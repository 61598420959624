$primary: black;

/* Safari 7.1+ */

_::-webkit-full-page-media,
_:future,
:root .down .btn-frame .whiteBg {
  width: 187px;
}

_::-webkit-full-page-media,
_:future,
:root .down .btn-frame img {
  width: 195px;
}

/* firefox */

@-moz-document url-prefix() {
  :root {
    .down {
      .btn-frame {
        .whiteBg {
          width: 178px;
          height: 42px;
        }
      }
    }
  }
}

.down {
  .btn-frame {
    .whiteBg {
      width: 173.17px;
    }
    img {
      width: 185px;
    }
  }
}

.btn-frame {
  // width: 178.19px;
  position: relative;
  margin-bottom: 3rem;
  // border: 1px solid firebrick;
  a.btn {
    color: $primary;
    text-decoration: none;
    -webkit-transition: 0.3s all ease;
    transition: 0.3s ease all;
    &:hover {
      color: #fff;
    }
    &:focus {
      color: #fff;
    }
  }
  .whiteBg {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
  }
  .btn {
    // font-size: 18px;
    // letter-spacing: 2px;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    padding: 10px 8px;
    border: 3px solid $primary;
    border-radius: 2px;
    position: relative;
    color: $primary;
    // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.1);
    &:before {
      -webkit-transition: 0.5s all ease;
      transition: 0.5s all ease;
      position: absolute;
      top: 0;
      left: 50%;
      right: 50%;
      bottom: 0;
      opacity: 0;
      content: '';
      background-color: $primary;
      z-index: -1;
      color: $primary;
    }
    &:hover {
      &:before {
        -webkit-transition: 0.5s all ease;
        transition: 0.5s all ease;
        left: 0;
        right: 0;
        opacity: 1;
        color: $primary;
      }
    }
    &:focus {
      &:before {
        transition: 0.5s all ease;
        left: 0;
        right: 0;
        opacity: 1;
        color: $primary;
      }
    }
  }
  img {
    // width: 185px;
    height: 45px;
    position: absolute;
    left: 0.3rem;
    top: 0.5rem;
    z-index: -3;
  }
}

.info-frame {
  a {
    width: 160px;
    height: 35px;
    position: relative;
    color: $primary;
    button {
      //for index button size
      min-width: 140px;
      padding: 6px 10px;
      background-color: white;
      border: 3px solid #000000;
      position: absolute;
      z-index: 2;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      text-transform: uppercase;
      -webkit-transition: 0.3s all ease;
      transition: 0.3s ease all;
      color: $primary;
      &:hover {
        color: #fff;
        cursor: pointer;
      }
      &:focus {
        color: #fff;
      }
      &:before {
        -webkit-transition: 0.5s all ease;
        transition: 0.5s all ease;
        position: absolute;
        top: 0;
        left: 50%;
        right: 50%;
        bottom: 0;
        opacity: 0;
        content: '';
        background-color: $primary;
        z-index: -1;
        color: $primary;
      }
      &:hover {
        &:before {
          -webkit-transition: 0.5s all ease;
          transition: 0.5s all ease;
          left: 0;
          right: 0;
          opacity: 1;
          color: $primary;
        }
      }
      &:focus {
        &:before {
          transition: 0.5s all ease;
          left: 0;
          right: 0;
          opacity: 1;
          color: $primary;
        }
      }
    }
    img {
      width: 140px;
      height: 35px;
      position: absolute;
      left: 0.5rem;
      top: 0.5rem;
      z-index: 0;
    }
  }
}

.btn-flex-wrap {
  display: flex;
}

/* Safari 7.1+ */

_::-webkit-full-page-media,
_:future,
:root .project-info .btn-frame .whiteBg {
  width: 152.58px;
  height: 42px;
}

_::-webkit-full-page-media,
_:future,
:root .project-info .btn-frame img {
  width: 164px;
}
/* firefox */
@-moz-document url-prefix() {
  :root {
    .project-info {
      .btn-frame {
        .whiteBg {
          width: 146px;
          height: 42px;
        }
      }
    }
  }
}
.project-info .btn-frame img {
  width: 155px;
}
.project-info {
  .btn-frame {
    &:hover {
      cursor: pointer;
    }
    .btn {
      z-index: 1;
      color: $primary;
    }
    .whiteBg {
      width: 141.58px;
      height: 42px;
    }
    img {
      width: 150px;
    }
  }
}
