.left-frame {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .home_bgcomp1 {
    position: absolute;
    right: 0;
    top: -2rem;
  }
  .home_bgcomp2 {
    position: absolute;
    left: 0;
    z-index: -5;
  }
  .home_bgcomp3 {
    position: absolute;
    right: 0;
  }
  .home_bgcomp4 {
    position: absolute;
    width: 18rem;
    bottom: 5rem;
    right: 5rem;
  }
  .home_bgcomp5 {
    position: absolute;
    // width: 18rem;
    top: 0;
    left: 0;
  }
  .home_bgcomp6 {
    position: absolute;
    // width: 18rem;
    bottom: 0;
    right: 0;
  }
  .main-info {
    position: absolute;
    width: 60%;
    height: 70%;
    // border: 1px solid pink;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .home_mainpic {
      position: absolute;
      right: 0;
      top: -2.5rem;
    }
    .top {
      width: 100%;
      height: 70%;
      z-index: 3;
      //   border: 1px solid green;
      .name {
        font-family: $Cabin;
        font-style: italic;
        font-weight: 500;
        font-size: 44px;
        line-height: 53px;
        letter-spacing: 1px;
      }
      .title {
        font-family: $Urbanist;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        margin-top: 0.8rem;
        margin-bottom: 1rem;
      }
      .code-info {
        font-family: $Urbanist;
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        line-height: 43px;
      }
      .love-frame {
        display: block;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        h1 {
          display: block;
        }
        img {
          width: 81px;
          height: 50px;
          position: absolute;
        }
        .arcContain {
          width: 200px;
          height: 600px;
          position: absolute;
          left: -40px;
          transform: rotate(80deg) scale(0.2);
        }
        .arc {
          width: 200px;
          height: 600px;
          border-radius: 50%;
          border: 20px solid #2cb8bf;
          border-bottom: 2px solid transparent;
          position: absolute;
          top: 0;
          right: 0;
          -moz-box-sizing: border-box;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
        }
        .archideLeft .arc {
          top: auto;
          bottom: 0;
          right: auto;
          left: 0;
        }
        .archide {
          width: 50%;
          height: 0%;
          position: absolute;
          top: 0;
          right: 0;
          overflow: hidden;
          -webkit-animation: appear 1.2s ease-in 1.2s forwards;
          -moz-animation: appear 1.2s ease-in 1.2s forwards;
          -o-animation: appear 1.2s ease-in 1.2s forwards;
          animation: appear 1.2s ease-in 1.2s forwards;
        }
        .archideLeft {
          top: auto;
          bottom: 0;
          right: auto;
          left: 0;
          -webkit-animation: appear 1.2s ease-out forwards;
          -moz-animation: appear 1.2s ease-out forwards;
          -o-animation: appear 1.2s ease-out forwards;
          animation: appear 1.2s ease-out forwards;
        }
        @-webkit-keyframes appear {
          to {
            height: 100%;
          }
        }
        @-moz-keyframes appear {
          to {
            height: 100%;
          }
        }
        @-o-keyframes appear {
          to {
            height: 100%;
          }
        }
        @keyframes appear {
          to {
            height: 100%;
          }
        }
      }
    }
    .down {
      width: 100%;
      height: 20%;
      display: flex;
      flex-direction: column;
      //   border: 1px solid darkorange;
      .link-frame {
        display: flex;
        a {
          width: 2rem;
          height: 2rem;
          margin-right: 1rem;
          &:hover {
            cursor: pointer;
          }
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media all and (max-width: 565px) {
  .left-frame {
    .main-info {
      width: 80%;
    }
  }
}
@media all and (max-width: 430px) {
  .left-frame {
    .main-info {
      height: 80%;
      .top {
        .code-info {
          font-size: 28px;
          line-height: 40px;
        }
      }
    }
  }
}
