.template-frame {
  width: 100vw;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .template {
    width: 80%;
    height: 100%;
    // padding: 40px 0;
    background-color: #f8f8f8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
    .tag {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 1rem;
      text-transform: uppercase;
      &.green {
        color: #288182;
      }
      &.golden {
        color: #cd8b06;
      }
      &.red {
        color: #c33c11;
      }
      &.blue {
        color: #0d3481;
      }
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 1.7em;
      line-height: 42px;
    }
    .bold {
      font-weight: bolder;
      &.green {
        background-color: #28818233;
      }
      &.golden {
        background-color: #f3e2ce;
      }
      &.red {
        background-color: #f9d6d4;
      }
      &.blue {
        background-color: #e4ebf9;
      }
    }
    h1 {
      font-family: $Urbanist;
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 48px;
      margin-bottom: 12px;
    }
    &.start {
      padding-top: 100px;
    }
    &.end {
      padding-bottom: 100px;
    }
    .info-frame {
      width: 80%;
    }
    .img-margin {
      margin-bottom: 80px;
    }

    .paragrph {
      padding: 30px;
      border-radius: 10px;
      p {
        font-style: italic;
        font-weight: 400;
        font-size: 20px;
        line-height: 2.1em;
        font-weight: bold;
      }
      &.golden {
        background-color: #f3e2ce;
      }
      &.red {
        background-color: #f9d6d4;
      }
      &.blue {
        background-color: #e4ebf9;
      }
      &.green {
        background-color: #a8d8dc;
      }
    }
    &.one {
      .info-frame {
        display: flex;
        flex-direction: column;
        ul {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-inline-start: 20px;
          li {
            font-weight: 400;
            font-size: 20px;
            line-height: 1.7em;
            line-height: 42px;
            &::before {
              font-size: 2rem;
            }
          }
        }
        ol {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-inline-start: 20px;
          li {
            font-family: $Urbanist;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 1.7em;
            line-height: 42px;
          }
        }
        img {
          width: 100%;
          height: 100%;
          // margin: 20px 0;
        }
      }
    }
    &.two {
      height: 70vh;
      .info-frame {
        height: 80%;
        display: flex;
        justify-content: space-between;
        .left {
          width: 45%;
          height: 80%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .right {
          width: 50%;
          background-color: #edf7f6;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          img {
            width: 80%;
          }
        }
      }
    }
    &.three {
      height: 70vh;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #edf7f6;
      img {
        width: 60%;
      }
    }
    &.four {
      height: 70vh;
      .info-frame {
        width: 80%;
        height: 80%;
        display: flex;
        justify-content: space-between;
        .left {
          width: 50%;
          background-color: #edf7f6;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          img {
            width: 80%;
          }
        }
        .right {
          width: 45%;
          height: 80%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
    &.five {
      padding-top: 32px;
      .info-frame {
        height: 80%;
        display: flex;
        align-items: center;
        position: relative;
        .line {
          width: 5px;
          height: 100%;
          position: absolute;
          left: -30px;
          &.green {
            background-color: #288182;
          }
          &.golden {
            background-color: #cd8b06;
          }
          &.red {
            background-color: #c33c11;
          }
          &.blue {
            background-color: #0d3481;
          }
        }
      }
    }
    &.six {
      .info-frame {
        height: 80%;
        p {
          font-family: $Urbanist;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 1.7em;
          line-height: 42px;
        }
      }
    }
    &.seven {
      .info-frame {
        display: flex;
        .left {
          width: 50%;
          .line-paragrph {
            width: 100%;
            position: relative;
            margin-top: 1.5rem;
            .line {
              width: 5px;
              height: 100%;
              position: absolute;
              left: -30px;
              &.green {
                background-color: #288182;
              }
              &.golden {
                background-color: #cd8b06;
              }
              &.red {
                background-color: #c33c11;
              }
              &.blue {
                background-color: #0d3481;
              }
            }
          }
        }
        .right {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 100%;
          }
        }
      }
    }
    &.center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

@media all and (max-width: 820px) {
  .template-frame {
    .template {
      &.seven {
        .info-frame {
          flex-direction: column;
          .left,
          .right {
            width: 100%;
          }
        }
      }
    }
  }
}

@media all and (max-width: 430px) {
  .template-frame {
    .template {
      width: 100%;
      .info-frame {
        width: 85%;
        // width: 100%;
        // padding: 0 24px;
      }
      &.five {
        .info-frame {
          .line {
            left: -16px;
          }
        }
      }
      &.seven {
        .info-frame {
          .left {
            .line-paragrph {
              .line {
                left: -16px;
              }
            }
          }
        }
      }
    }
  }
}
