@import url(https://fonts.googleapis.com/css2?family=Urbanist&family=Cabin&family=Zen+Tokyo+Zoo&family=JetBrains+Mono);
body {
  margin: 0;
  padding: 0;
  font-family: "Urbanist", "Cabin", "Zen Tokyo Zoo", "JetBrains Mono", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  border: 15px solid #000000;
  position: relative;
}
.wrapper .left {
  width: 100%;
  height: 100%;
  display: flex;
}
.wrapper .left .left-frame {
  width: 40%;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  top: 0;
  border-right: 15px solid #000000;
}
.wrapper .left .right {
  width: 60%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
}
.wrapper .left .right .about {
  width: 100%;
  height: 90vh;
}
.wrapper .left .right .about .bg-block {
  width: 90%;
  height: 95%;
  background-color: #a1dbda;
  border-radius: 0px 0px 130px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper .left .right .about .bg-block .about-info {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.wrapper .left .right .about .bg-block .about-info h4 {
  margin-bottom: 1rem;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
  margin-top: 1rem;
}
.wrapper .left .right .about .bg-block .about-info p {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 1rem;
}
.wrapper .left .right .about .bg-block .about-info .icon-frame {
  display: flex;
  align-items: center;
}
.wrapper .left .right .about .bg-block .about-info .icon-frame img {
  width: 2.5rem;
  margin-right: 1rem;
}
.wrapper .left .right .project {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem 0;
}
.wrapper .left .right .project .info-frame {
  padding: 0 16px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.wrapper .left .right .project .info-frame .title {
  display: flex;
  align-items: center;
}
.wrapper .left .right .project .info-frame .title h1 {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-transform: capitalize;
}
.wrapper .left .right .project .info-frame .title h3 {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
}
.wrapper .left .right .project .info-frame .title h5 {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.wrapper .left .right .project .info-frame .title.flex-start {
  align-items: flex-start;
}
.wrapper .left .right .project .info-frame.white {
  color: white;
}
.wrapper .left .right .project .info-frame h4 {
  margin-top: 4px;
  margin-bottom: 8px;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  text-transform: capitalize;
}
.wrapper .left .right .project .info-frame a {
  margin-top: 8px;
}
.wrapper .left .right .project.one {
  background-color: #138b81;
  align-items: flex-start;
}
.wrapper .left .right .project.one .img-frame {
  width: 85%;
  height: 100%;
  align-self: flex-end;
  background-image: url(/static/media/project_ctbc.18ce8488.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.wrapper .left .right .project.two {
  background-color: #2cb8bf;
}
.wrapper .left .right .project.two .img-frame {
  width: 100%;
  height: 100%;
  align-self: flex-end;
  background-image: url(/static/media/shanghai-0-prototype.00d52923.gif);
  background-size: 28%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.wrapper .left .right .project.three {
  background-color: #1b1c1c;
}
.wrapper .left .right .project.three .img-frame {
  width: 100%;
  height: 100%;
  align-self: flex-end;
  background-image: url(/static/media/project_frontlineAirsoft.4d26320b.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.wrapper .left .right .project.four {
  background-color: #95b7ff;
  align-items: flex-start;
}
.wrapper .left .right .project.four .img-frame {
  width: 85%;
  height: 100%;
  align-self: flex-end;
  background-image: url(/static/media/xslego-1-cover.a66f110f.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.wrapper .left .right .project.five {
  background-color: #62dfc6;
}
.wrapper .left .right .project.five .img-frame {
  width: 100%;
  height: 100%;
  align-self: flex-end;
  background-image: url(/static/media/project_tcbBank.c1e2af4b.png);
  background-size: 120%;
  background-repeat: no-repeat;
  background-position: center;
}
.wrapper .left .right .project.six {
  background-color: #ffe978;
  background-color: #92b4ea;
}
.wrapper .left .right .project.six .img-frame {
  width: 100%;
  height: 100%;
  align-self: center;
  background-image: url(/static/media/project_alphritter-2.ddd82650.png);
  /* border-radius: 50px; */
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: center;
}

.page {
  position: absolute;
  width: 100vw;
  height: 100vh;
  right: 0;
  bottom: 0;
  opacity: 0;
  background-color: white;
}
.page.open {
  transition: 0.8s;
  opacity: 1;
  z-index: 3;
}

.left-layer {
  position: fixed;
  width: 200%;
  height: 200%;
  top: 0;
  right: -200%;
  background-color: #138b81;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 5;
}
.left-layer.active {
  right: 200%;
}
.left-layer.left-layer--2 {
  background-color: #fed002;
  transition-delay: 0.3s;
}
.left-layer.left-layer--3 {
  background-color: #51a2a5;
  transition-delay: 0.3s;
}
.left-layer.left-layer--4 {
  background-color: #9dd3d4;
  transition-delay: 0.3s;
}

@media all and (max-width: 820px) {
  .wrapper {
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
  }
  .wrapper .left {
    width: 100%;
    height: 100%;
    border-right: none;
    flex-direction: column;
  }
  .wrapper .left .left-frame {
    width: 100%;
    height: 100vh;
    position: relative;
    border-right: none;
    border-bottom: 15px solid #000000;
  }
  .wrapper .left .right {
    width: 100%;
  }
  .wrapper .left .project.two .img-frame {
    background-size: 35%;
  }
  .wrapper .left-layer {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: -100%;
    bottom: auto;
    left: auto;
    z-index: 5;
  }
  .wrapper .left-layer.active {
    right: 100%;
  }
}
@media all and (max-width: 430px) {
  .wrapper .left .right .project .info-frame {
    max-width: 100%;
  }
  .wrapper .left .right .project.one .img-frame {
    background-size: 90vh;
    background-repeat: no-repeat;
    background-position: left center;
  }
  .wrapper .left .right .project.two .img-frame {
    background-size: 65%;
  }
  .wrapper .left .right .project .tech-tag {
    margin-bottom: 8px;
  }
  .wrapper .left .right .about {
    height: 100%;
  }
  .wrapper .left .right .about .bg-block {
    padding: 2rem 0;
    margin-bottom: 2rem;
  }
  .wrapper .left .right .about .bg-block .about-info {
    width: 95%;
  }
  .wrapper .left .right .about .bg-block .about-info .icon-frame img {
    width: 2rem;
  }
  .wrapper .left .right .about .bg-block .about-info p {
    font-size: 16px;
    line-height: 28px;
  }
}
/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .wrapper .left .right .project.one .img-frame {
    background-size: 135%;
    background-position: left center;
  }
  .wrapper .left .right .project.two .img-frame {
    background-size: 35%;
  }
}
/* ----------- iPad 3, 4 and Pro 9.7" ----------- */
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .wrapper .left .right .project.one .img-frame {
    background-size: 135%;
    background-position: left center;
  }
  .wrapper .left .right .project.two .img-frame {
    background-size: 35%;
  }
}
/* ----------- iPad Pro 10.5" ----------- */
/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .wrapper .left .right .project.one .img-frame {
    background-size: 135%;
    background-position: left center;
  }
  .wrapper .left .right .project.two .img-frame {
    background-size: 50%;
  }
}
/* ----------- iPad Pro 12.9" ----------- */
/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .wrapper .left .right .project.one .img-frame {
    background-size: 135%;
    background-position: left center;
  }
  .wrapper .left .right .project.two .img-frame {
    background-size: 50%;
  }
}
.page-frame.ctbc .block-two {
  background-color: #4aa5a7;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.page-frame.ctbc .block-two .img-frame {
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url(/static/media/CTBC-UX-0-cover.1de8ead0.png);
  background-size: 78%;
  background-repeat: no-repeat;
  background-position: 70% 42%;
}

@media all and (min-width: 2047px) {
  .page-frame.ctbc .block-two .img-frame {
    background-size: 1500px;
    background-repeat: no-repeat;
    background-position: 55% 34%;
  }
}
@media all and (max-width: 1200px) {
  .page-frame.ctbc .block-two .img-frame {
    background-size: 100%;
    background-position: 8vw center;
  }
}
@media all and (max-width: 430px) {
  .page-frame.ctbc .block-two .img-frame {
    background-image: url(/static/media/CTBC-UX-1-cover-right.9dceeec5.png);
    background-size: 58%;
    background-repeat: no-repeat;
    background-position: 60% center;
  }
}
.page-frame.fa .block-two {
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-frame.fa .block-two img {
  width: 50%;
}

@media all and (max-width: 1500px) {
  .page-frame.fa .block-two img {
    width: 60%;
  }
}
@media all and (max-width: 1200px) {
  .page-frame.fa .block-two img {
    width: 70%;
  }
}
@media all and (max-width: 1000px) {
  .page-frame.fa .block-two img {
    width: 80%;
  }
}
@media all and (max-width: 700px) {
  .page-frame.fa .block-two img {
    width: 85%;
  }
}
@media all and (max-width: 430px) {
  .page-frame.fa .block-two img {
    width: 90%;
  }
}
.page-frame.scsb .block-two {
  background-color: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-frame.scsb .block-two .img-frame {
  width: 100%;
  height: 100%;
  position: relative;
  background-image: url(/static/media/shanghai-3-cover.df653513.png), url(/static/media/shanghai-2-bk.616554a0.png);
  background-size: 85%, cover;
  background-repeat: no-repeat, no-repeat;
  background-position: center 42%;
}
.page-frame.scsb .template .gif-frame {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-frame.scsb .template .gif-frame .phone-gif1 {
  width: 28%;
}
.page-frame.scsb .template .gif-frame .phone-gif2 {
  width: 30%;
  margin-bottom: 80px;
}
.page-frame.scsb .template .gif-frame .phone-gif3 {
  width: 60%;
  margin-bottom: 80px;
}
.page-frame.scsb .template.one .info-frame img.design-img {
  width: 70%;
  align-self: center;
  margin-bottom: 80px;
}

@media all and (min-width: 2047px) {
  .page-frame.scsb .block-two .img-frame {
    background-image: url(/static/media/shanghai-3-cover.df653513.png), url(/static/media/shanghai-2-bk.616554a0.png);
    background-size: 1500px, cover;
    background-repeat: no-repeat, no-repeat;
    background-position: center, center 48%;
  }
}
@media all and (max-width: 1200px) {
  .page-frame.scsb .block-two .img-frame {
    background-size: 110%, cover;
  }
}
@media (max-width: 820px) {
  .page-frame.scsb .block-two .img-frame {
    background-image: url(/static/media/shanghai-3-cover.df653513.png), url(/static/media/shanghai-2-bk.616554a0.png);
    background-size: 100%, cover;
    background-repeat: no-repeat, no-repeat;
    background-position: 1vw, center;
  }
  .page-frame.scsb .template .gif-frame .phone-gif1 {
    width: 55%;
  }
  .page-frame.scsb .template .gif-frame .phone-gif2 {
    width: 55%;
  }
  .page-frame.scsb .template .gif-frame .phone-gif3 {
    width: 70%;
  }
  .page-frame.scsb .template.one .info-frame img.design-img {
    width: 100%;
  }
}
@media (max-width: 430px) {
  .page-frame.scsb .block-two .img-frame {
    background-image: url(/static/media/shanghai-1-cover.61eb70fb.png), url(/static/media/shanghai-2-bk.616554a0.png);
    background-size: 125%, cover;
    background-repeat: no-repeat, no-repeat;
    background-position: center, center 48%;
  }
  .page-frame.scsb .template .gif-frame .phone-gif1 {
    width: 65%;
  }
  .page-frame.scsb .template .gif-frame .phone-gif2 {
    width: 65%;
  }
  .page-frame.scsb .template .gif-frame .phone-gif3 {
    width: 100%;
  }
}
/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .page-frame.scsb .block-two .img-frame {
    background-image: url(/static/media/shanghai-3-cover.df653513.png), url(/static/media/shanghai-2-bk.616554a0.png);
    background-size: 100%, cover;
    background-repeat: no-repeat, no-repeat;
    background-position: center, center 48%;
  }
  .page-frame.scsb .template .gif-frame .phone-gif1 {
    width: 55%;
  }
  .page-frame.scsb .template .gif-frame .phone-gif2 {
    width: 55%;
  }
  .page-frame.scsb .template .gif-frame .phone-gif3 {
    width: 70%;
  }
}
/* ----------- iPad 3, 4 and Pro 9.7" ----------- */
/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .page-frame.scsb .block-two .img-frame {
    background-image: url(/static/media/shanghai-3-cover.df653513.png), url(/static/media/shanghai-2-bk.616554a0.png);
    background-size: 100%, cover;
    background-repeat: no-repeat, no-repeat;
    background-position: center, center 48%;
  }
  .page-frame.scsb .template .gif-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .page-frame.scsb .template .gif-frame .phone-gif1 {
    width: 55%;
  }
  .page-frame.scsb .template .gif-frame .phone-gif2 {
    width: 55%;
    margin-bottom: 80px;
  }
  .page-frame.scsb .template .gif-frame .phone-gif3 {
    width: 70%;
  }
  .page-frame.scsb .template.one .info-frame img.design-img {
    width: 100%;
    align-self: center;
    margin-bottom: 80px;
  }
}
/* ----------- iPad Pro 10.5" ----------- */
/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .page-frame.scsb .block-two .img-frame {
    background-image: url(/static/media/shanghai-3-cover.df653513.png), url(/static/media/shanghai-2-bk.616554a0.png);
    background-size: 100%, cover;
    background-repeat: no-repeat, no-repeat;
    background-position: center, center 48%;
  }
  .page-frame.scsb .template .gif-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .page-frame.scsb .template .gif-frame .phone-gif1 {
    width: 55%;
  }
  .page-frame.scsb .template .gif-frame .phone-gif2 {
    width: 55%;
    margin-bottom: 80px;
  }
  .page-frame.scsb .template .gif-frame .phone-gif3 {
    width: 70%;
  }
  .page-frame.scsb .template.one .info-frame img.design-img {
    width: 100%;
    align-self: center;
    margin-bottom: 80px;
  }
}
/* ----------- iPad Pro 12.9" ----------- */
/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .page-frame.scsb .block-two .img-frame {
    background-image: url(/static/media/shanghai-3-cover.df653513.png), url(/static/media/shanghai-2-bk.616554a0.png);
    background-size: 100%, cover;
    background-repeat: no-repeat, no-repeat;
    background-position: center, center 48%;
  }
  .page-frame.scsb .template .gif-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .page-frame.scsb .template .gif-frame .phone-gif1 {
    width: 55%;
  }
  .page-frame.scsb .template .gif-frame .phone-gif2 {
    width: 55%;
    margin-bottom: 80px;
  }
  .page-frame.scsb .template .gif-frame .phone-gif3 {
    width: 70%;
  }
  .page-frame.scsb .template.one .info-frame img.design-img {
    width: 100%;
    align-self: center;
    margin-bottom: 80px;
  }
}
.page-frame.xslego .block-two {
  background: linear-gradient(260.94deg, #95b7ff 4.68%, #254fa9 100.18%);
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-frame.xslego .block-two img {
  width: 55%;
}

@media all and (max-width: 1500px) {
  .page-frame.xslego .block-two img {
    width: 60%;
  }
}
@media all and (max-width: 1200px) {
  .page-frame.xslego .block-two img {
    width: 70%;
  }
}
@media all and (max-width: 1000px) {
  .page-frame.xslego .block-two img {
    width: 80%;
  }
}
@media all and (max-width: 700px) {
  .page-frame.xslego .block-two img {
    width: 85%;
  }
}
@media all and (max-width: 430px) {
  .page-frame.xslego .block-two img {
    width: 90%;
  }
}
* p,
* h1,
* h2,
* h3,
* h4,
* h5 {
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  padding: 0;
}

*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 0px;
  border-radius: 0px;
  background-clip: padding-box;
  border: 0px solid transparent;
}

footer.home-footer {
  width: 100%;
  height: 15vh;
  background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}
footer.home-footer .top {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
footer.home-footer .top h3 {
  margin-right: 0.5rem;
}
footer.home-footer .top a {
  color: white;
}
footer.home-footer .top a:link {
  text-decoration: none;
  color: white;
}
footer.home-footer .top a:visited {
  text-decoration: none;
  color: white;
}
footer.home-footer .top a:hover {
  text-decoration: none;
  cursor: pointer;
  color: #25bdbf;
}
footer.home-footer .top a:active {
  text-decoration: none;
  color: white;
}
footer.home-footer .top .link-frame {
  display: flex;
}
footer.project-footer {
  width: 100%;
  height: 15vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: white;
}
footer.project-footer a {
  list-style: none;
  color: white;
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  text-align: right;
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
}
footer.project-footer a.prev {
  display: flex;
}
footer.project-footer a.prev .prev-detail {
  margin-left: 1rem;
}
footer.project-footer a.next {
  display: flex;
}
footer.project-footer a.next .next-detail {
  margin-right: 1rem;
}
footer.project-footer a:hover {
  cursor: pointer;
  color: #25bdbf;
}

@media all and (max-width: 430px) {
  footer.home-footer .top {
    flex-direction: column;
  }
  footer.project-footer a {
    flex-direction: column;
  }
  footer.project-footer a.prev {
    flex-direction: column;
    align-items: flex-start;
  }
  footer.project-footer a.prev .prev-detail {
    margin-left: 0rem;
  }
  footer.project-footer a.next {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  footer.project-footer a.next .next-detail {
    margin-right: 0rem;
  }
}
.page-frame {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page-frame .block-two {
  width: 100%;
  height: 75vh;
}

.left-frame {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.left-frame .home_bgcomp1 {
  position: absolute;
  right: 0;
  top: -2rem;
}
.left-frame .home_bgcomp2 {
  position: absolute;
  left: 0;
  z-index: -5;
}
.left-frame .home_bgcomp3 {
  position: absolute;
  right: 0;
}
.left-frame .home_bgcomp4 {
  position: absolute;
  width: 18rem;
  bottom: 5rem;
  right: 5rem;
}
.left-frame .home_bgcomp5 {
  position: absolute;
  top: 0;
  left: 0;
}
.left-frame .home_bgcomp6 {
  position: absolute;
  bottom: 0;
  right: 0;
}
.left-frame .main-info {
  position: absolute;
  width: 60%;
  height: 70%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.left-frame .main-info .home_mainpic {
  position: absolute;
  right: 0;
  top: -2.5rem;
}
.left-frame .main-info .top {
  width: 100%;
  height: 70%;
  z-index: 3;
}
.left-frame .main-info .top .name {
  font-family: "Cabin";
  font-style: italic;
  font-weight: 500;
  font-size: 44px;
  line-height: 53px;
  letter-spacing: 1px;
}
.left-frame .main-info .top .title {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  margin-top: 0.8rem;
  margin-bottom: 1rem;
}
.left-frame .main-info .top .code-info {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 43px;
}
.left-frame .main-info .top .love-frame {
  display: block;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.left-frame .main-info .top .love-frame h1 {
  display: block;
}
.left-frame .main-info .top .love-frame img {
  width: 81px;
  height: 50px;
  position: absolute;
}
.left-frame .main-info .top .love-frame .arcContain {
  width: 200px;
  height: 600px;
  position: absolute;
  left: -40px;
  -webkit-transform: rotate(80deg) scale(0.2);
          transform: rotate(80deg) scale(0.2);
}
.left-frame .main-info .top .love-frame .arc {
  width: 200px;
  height: 600px;
  border-radius: 50%;
  border: 20px solid #2cb8bf;
  border-bottom: 2px solid transparent;
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
}
.left-frame .main-info .top .love-frame .archideLeft .arc {
  top: auto;
  bottom: 0;
  right: auto;
  left: 0;
}
.left-frame .main-info .top .love-frame .archide {
  width: 50%;
  height: 0%;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  -webkit-animation: appear 1.2s ease-in 1.2s forwards;
  animation: appear 1.2s ease-in 1.2s forwards;
}
.left-frame .main-info .top .love-frame .archideLeft {
  top: auto;
  bottom: 0;
  right: auto;
  left: 0;
  -webkit-animation: appear 1.2s ease-out forwards;
  animation: appear 1.2s ease-out forwards;
}
@-webkit-keyframes appear {
  to {
    height: 100%;
  }
}
@keyframes appear {
  to {
    height: 100%;
  }
}
.left-frame .main-info .down {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
}
.left-frame .main-info .down .link-frame {
  display: flex;
}
.left-frame .main-info .down .link-frame a {
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
}
.left-frame .main-info .down .link-frame a:hover {
  cursor: pointer;
}
.left-frame .main-info .down .link-frame img {
  width: 100%;
  height: 100%;
}

@media all and (max-width: 565px) {
  .left-frame .main-info {
    width: 80%;
  }
}
@media all and (max-width: 430px) {
  .left-frame .main-info {
    height: 80%;
  }
  .left-frame .main-info .top .code-info {
    font-size: 28px;
    line-height: 40px;
  }
}
/* Safari 7.1+ */
_::-webkit-full-page-media,
_:future,
:root .down .btn-frame .whiteBg {
  width: 187px;
}

_::-webkit-full-page-media,
_:future,
:root .down .btn-frame img {
  width: 195px;
}

/* firefox */
@-moz-document url-prefix() {
  :root .down .btn-frame .whiteBg {
    width: 178px;
    height: 42px;
  }
}
.down .btn-frame .whiteBg {
  width: 173.17px;
}
.down .btn-frame img {
  width: 185px;
}

.btn-frame {
  position: relative;
  margin-bottom: 3rem;
}
.btn-frame a.btn {
  color: black;
  text-decoration: none;
  transition: 0.3s ease all;
}
.btn-frame a.btn:hover {
  color: #fff;
}
.btn-frame a.btn:focus {
  color: #fff;
}
.btn-frame .whiteBg {
  width: 100%;
  height: 100%;
  background-color: #fff;
  position: absolute;
  z-index: -2;
  top: 0;
  left: 0;
}
.btn-frame .btn {
  text-transform: uppercase;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  padding: 10px 8px;
  border: 3px solid black;
  border-radius: 2px;
  position: relative;
  color: black;
}
.btn-frame .btn:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: "";
  background-color: black;
  z-index: -1;
  color: black;
}
.btn-frame .btn:hover:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
  color: black;
}
.btn-frame .btn:focus:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
  color: black;
}
.btn-frame img {
  height: 45px;
  position: absolute;
  left: 0.3rem;
  top: 0.5rem;
  z-index: -3;
}

.info-frame a {
  width: 160px;
  height: 35px;
  position: relative;
  color: black;
}
.info-frame a button {
  min-width: 140px;
  padding: 6px 10px;
  background-color: white;
  border: 3px solid #000000;
  position: absolute;
  z-index: 2;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s ease all;
  color: black;
}
.info-frame a button:hover {
  color: #fff;
  cursor: pointer;
}
.info-frame a button:focus {
  color: #fff;
}
.info-frame a button:before {
  transition: 0.5s all ease;
  position: absolute;
  top: 0;
  left: 50%;
  right: 50%;
  bottom: 0;
  opacity: 0;
  content: "";
  background-color: black;
  z-index: -1;
  color: black;
}
.info-frame a button:hover:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
  color: black;
}
.info-frame a button:focus:before {
  transition: 0.5s all ease;
  left: 0;
  right: 0;
  opacity: 1;
  color: black;
}
.info-frame a img {
  width: 140px;
  height: 35px;
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  z-index: 0;
}

.btn-flex-wrap {
  display: flex;
}

/* Safari 7.1+ */
_::-webkit-full-page-media,
_:future,
:root .project-info .btn-frame .whiteBg {
  width: 152.58px;
  height: 42px;
}

_::-webkit-full-page-media,
_:future,
:root .project-info .btn-frame img {
  width: 164px;
}

/* firefox */
@-moz-document url-prefix() {
  :root .project-info .btn-frame .whiteBg {
    width: 146px;
    height: 42px;
  }
}
.project-info .btn-frame img {
  width: 155px;
}

.project-info .btn-frame:hover {
  cursor: pointer;
}
.project-info .btn-frame .btn {
  z-index: 1;
  color: black;
}
.project-info .btn-frame .whiteBg {
  width: 141.58px;
  height: 42px;
}
.project-info .btn-frame img {
  width: 150px;
}

.topBtn-frame {
  position: fixed;
  right: 5%;
  bottom: 13%;
  z-index: 10;
  cursor: pointer;
}
.topBtn-frame .topBtn {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 50%;
  border: 2px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topBtn-frame svg {
  font-size: x-large;
  stroke-width: 1;
}
.topBtn-frame .topbtn-bg {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAATYAAABGCAYAAABG6bjcAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAFqSURBVHgB7dqxDYNAFAXBw3Jj0BcRfUFpmBDTAauZ0Nr4ibP+dF7GzXEcY9/3+09j27ah0Wg0b2k+AyDGsAE5hg3Ima636jkAQr7PP+GWZRnzPP/9tq6rRqPRvKbxFAVyDBuQY9iAnMmBrkajqTW+2IAcwwbkGDYgx4EukONAV6PR5BpPUSDHsAE5hg3IcaCr0WhyjS82IMewATmGDchxoAvkONDVaDS5xlMUyDFsQI5hA3Ic6Go0mlzjiw3IMWxAjmEDchzoAjkOdDUaTa7xFAVyDBuQY9iAHAe6Go0m1/hiA3IMG5Bj2IAcB7pAjgNdjUaTazxFgRzDBuQYNiDHga5Go8k1vtiAHMMG5Bg2IMeBLpDjQFej0eQaT1Egx7ABOYYNyHGgq9Foco0vNiDHsAE5hg3IcaAL5DjQ1Wg0ucZTFMgxbECOYQNyHOhqNJpc44sNyDFsQI5hA3Ic6AI5DnQ1Gk2u8RQFcn7CsdyMI/+x+wAAAABJRU5ErkJggg==);
  background-size: contain;
  position: absolute;
  right: -7px;
  top: 4px;
  z-index: -2;
}

.project {
  width: 100%;
  height: 90vh;
}
.project .tech-tag {
  display: inline-block;
  width: auto;
  padding: 4px 8px;
  margin: 0 4px;
  border-radius: 17px;
  background-color: rgba(59, 66, 74, 0.568627451);
  color: #ffffff;
}
.project .tech-tag:first-child {
  margin-left: 8px;
}
.project.one {
  background-color: #138b81;
}
.project.two {
  background-color: #2cb8bf;
}
.project.three {
  background-color: #1b1c1c;
}
.project.four {
  background-color: #95b7ff;
}
.project.five {
  background-color: #62dfc6;
}
.project.six {
  background-color: #1b1c1c;
}
.project-info {
  width: 100vw;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.project-info .header {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 0;
}
.project-info .header .inverted-1 {
  display: flex;
  align-items: center;
  background: linear-gradient(black 50%, #fff 0) 0 0/100% 200% no-repeat, linear-gradient(rgba(0, 0, 0, 0) 50%, #000 0) 0 0/100% 200% no-repeat;
  background: linear-gradient(black 50%, #fff 0) 0 var(--_d, 0)/100% 200% no-repeat, linear-gradient(rgba(0, 0, 0, 0) 50%, #000 0) 0 var(--_d, 0)/100% 200% no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: 0.8s;
  font-size: 16px;
}
.project-info .header .inverted-1 a {
  text-decoration: none;
  color: black;
  font-weight: bolder;
  color: rgba(0, 0, 0, 0);
  font-weight: 700;
  display: inline-block;
  font-size: 20px;
  padding: 8px;
  font-family: "Urbanist";
  text-transform: uppercase;
  cursor: pointer;
}
.project-info .header .inverted-1 span {
  font-family: system-ui;
  font-weight: bolder;
}
.project-info .header .inverted-1:hover {
  --_d: 50%;
}
.project-info .header .backBtn {
  --s: 0.1em; /* the thickness of the line */
  --c: #000000; /* the color */
  color: rgba(0, 0, 0, 0);
  padding-bottom: var(--s);
  background: linear-gradient(90deg, var(--c) 50%, #000 0) calc(100% - 0%)/200% 100%, linear-gradient(var(--c) 0 0) 0% 100%/0% var(--s) no-repeat;
  background: linear-gradient(90deg, var(--c) 50%, #000 0) calc(100% - var(--_p, 0%))/200% 100%, linear-gradient(var(--c) 0 0) 0% 100%/var(--_p, 0%) var(--s) no-repeat;
  -webkit-background-clip: text, padding-box;
  background-clip: text, padding-box;
  transition: 0.5s;
  font-family: "Urbanist";
}
.project-info .header .backBtn a {
  text-decoration: none;
  font-weight: bolder;
  color: #000000;
  font-weight: 700;
  display: inline-block;
  font-size: 20px;
  padding: 8px;
  font-family: "Urbanist";
  text-transform: uppercase;
  cursor: pointer;
}
.project-info .header .backBtn:hover {
  --_p: 100%;
}
.project-info .main-block {
  width: 80%;
  border: 15px solid black;
  background-color: #f8f8f8;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}
.project-info .main-block .main-frame {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.project-info .main-block .main-frame .title {
  margin-bottom: 12px;
  font-family: "JetBrains Mono";
  font-size: 42px;
  line-height: 52px;
  display: block;
  font-weight: 400;
}
.project-info .main-block .main-frame .type {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  margin-bottom: 16px;
}
.project-info .main-block .main-frame p {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.7em;
  margin-bottom: 10px;
}
.project-info .main-block .main-frame .detail {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.7em;
  margin-bottom: 0;
}
.project-info .main-block .main-frame .line {
  border: 1px solid black;
  margin: 20px 0;
}
.project-info .main-block .main-frame .bottom {
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 2;
}
.project-info .main-block .main-frame .left {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.project-info .main-block .main-frame .role,
.project-info .main-block .main-frame .respon {
  display: flex;
  flex-direction: column;
}
.project-info .main-block .main-frame .role h3,
.project-info .main-block .main-frame .respon h3 {
  margin-bottom: 1rem;
}
.project-info .main-block .main-frame .role p,
.project-info .main-block .main-frame .respon p {
  width: 100%;
}

@media all and (max-width: 1000px) {
  .project-info .main-block .main-frame .left {
    width: 65%;
  }
}
@media all and (max-width: 840px) {
  .project-info .main-block .main-frame .bottom {
    flex-direction: column;
  }
  .project-info .main-block .main-frame .bottom .left {
    width: 100%;
  }
}
@media all and (max-width: 430px) {
  .project-info .header {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 26px 0;
  }
  .project-info .header img {
    width: 60px;
    height: 60px;
  }
  .project-info .main-block {
    width: 100%;
    box-sizing: border-box;
    padding: 36px 0;
  }
  .project-info .main-block .main-frame {
    width: 90%;
  }
  .project-info .main-block .main-frame p {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }
  .project-info .main-block .main-frame .detail {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
  }
  .project-info .main-block .main-frame .bottom {
    flex-direction: column;
  }
  .project-info .main-block .main-frame .bottom .left {
    flex-direction: column;
    width: 100%;
  }
  .project-info .main-block .main-frame .bottom .left .role {
    margin-bottom: 0.5rem;
  }
  .project-info .main-block .main-frame .bottom .btn-frame {
    position: relative;
    margin-bottom: 8px;
    margin-top: 24px;
  }
}
.template-frame {
  width: 100vw;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.template-frame .template {
  width: 80%;
  height: 100%;
  background-color: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 80px;
}
.template-frame .template .tag {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.template-frame .template .tag.green {
  color: #288182;
}
.template-frame .template .tag.golden {
  color: #cd8b06;
}
.template-frame .template .tag.red {
  color: #c33c11;
}
.template-frame .template .tag.blue {
  color: #0d3481;
}
.template-frame .template p {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.7em;
  line-height: 42px;
}
.template-frame .template .bold {
  font-weight: bolder;
}
.template-frame .template .bold.green {
  background-color: rgba(40, 129, 130, 0.2);
}
.template-frame .template .bold.golden {
  background-color: #f3e2ce;
}
.template-frame .template .bold.red {
  background-color: #f9d6d4;
}
.template-frame .template .bold.blue {
  background-color: #e4ebf9;
}
.template-frame .template h1 {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 12px;
}
.template-frame .template.start {
  padding-top: 100px;
}
.template-frame .template.end {
  padding-bottom: 100px;
}
.template-frame .template .info-frame {
  width: 80%;
}
.template-frame .template .img-margin {
  margin-bottom: 80px;
}
.template-frame .template .paragrph {
  padding: 30px;
  border-radius: 10px;
}
.template-frame .template .paragrph p {
  font-style: italic;
  font-weight: 400;
  font-size: 20px;
  line-height: 2.1em;
  font-weight: bold;
}
.template-frame .template .paragrph.golden {
  background-color: #f3e2ce;
}
.template-frame .template .paragrph.red {
  background-color: #f9d6d4;
}
.template-frame .template .paragrph.blue {
  background-color: #e4ebf9;
}
.template-frame .template .paragrph.green {
  background-color: #a8d8dc;
}
.template-frame .template.one .info-frame {
  display: flex;
  flex-direction: column;
}
.template-frame .template.one .info-frame ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-padding-start: 20px;
          padding-inline-start: 20px;
}
.template-frame .template.one .info-frame ul li {
  font-weight: 400;
  font-size: 20px;
  line-height: 1.7em;
  line-height: 42px;
}
.template-frame .template.one .info-frame ul li::before {
  font-size: 2rem;
}
.template-frame .template.one .info-frame ol {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  -webkit-padding-start: 20px;
          padding-inline-start: 20px;
}
.template-frame .template.one .info-frame ol li {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 1.7em;
  line-height: 42px;
}
.template-frame .template.one .info-frame img {
  width: 100%;
  height: 100%;
}
.template-frame .template.two {
  height: 70vh;
}
.template-frame .template.two .info-frame {
  height: 80%;
  display: flex;
  justify-content: space-between;
}
.template-frame .template.two .info-frame .left {
  width: 45%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.template-frame .template.two .info-frame .right {
  width: 50%;
  background-color: #edf7f6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.template-frame .template.two .info-frame .right img {
  width: 80%;
}
.template-frame .template.three {
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #edf7f6;
}
.template-frame .template.three img {
  width: 60%;
}
.template-frame .template.four {
  height: 70vh;
}
.template-frame .template.four .info-frame {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: space-between;
}
.template-frame .template.four .info-frame .left {
  width: 50%;
  background-color: #edf7f6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.template-frame .template.four .info-frame .left img {
  width: 80%;
}
.template-frame .template.four .info-frame .right {
  width: 45%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.template-frame .template.five {
  padding-top: 32px;
}
.template-frame .template.five .info-frame {
  height: 80%;
  display: flex;
  align-items: center;
  position: relative;
}
.template-frame .template.five .info-frame .line {
  width: 5px;
  height: 100%;
  position: absolute;
  left: -30px;
}
.template-frame .template.five .info-frame .line.green {
  background-color: #288182;
}
.template-frame .template.five .info-frame .line.golden {
  background-color: #cd8b06;
}
.template-frame .template.five .info-frame .line.red {
  background-color: #c33c11;
}
.template-frame .template.five .info-frame .line.blue {
  background-color: #0d3481;
}
.template-frame .template.six .info-frame {
  height: 80%;
}
.template-frame .template.six .info-frame p {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 1.7em;
  line-height: 42px;
}
.template-frame .template.seven .info-frame {
  display: flex;
}
.template-frame .template.seven .info-frame .left {
  width: 50%;
}
.template-frame .template.seven .info-frame .left .line-paragrph {
  width: 100%;
  position: relative;
  margin-top: 1.5rem;
}
.template-frame .template.seven .info-frame .left .line-paragrph .line {
  width: 5px;
  height: 100%;
  position: absolute;
  left: -30px;
}
.template-frame .template.seven .info-frame .left .line-paragrph .line.green {
  background-color: #288182;
}
.template-frame .template.seven .info-frame .left .line-paragrph .line.golden {
  background-color: #cd8b06;
}
.template-frame .template.seven .info-frame .left .line-paragrph .line.red {
  background-color: #c33c11;
}
.template-frame .template.seven .info-frame .left .line-paragrph .line.blue {
  background-color: #0d3481;
}
.template-frame .template.seven .info-frame .right {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.template-frame .template.seven .info-frame .right img {
  width: 100%;
}
.template-frame .template.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media all and (max-width: 820px) {
  .template-frame .template.seven .info-frame {
    flex-direction: column;
  }
  .template-frame .template.seven .info-frame .left,
.template-frame .template.seven .info-frame .right {
    width: 100%;
  }
}
@media all and (max-width: 430px) {
  .template-frame .template {
    width: 100%;
  }
  .template-frame .template .info-frame {
    width: 85%;
  }
  .template-frame .template.five .info-frame .line {
    left: -16px;
  }
  .template-frame .template.seven .info-frame .left .line-paragrph .line {
    left: -16px;
  }
}
.parting-line {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  font-family: "Zen Tokyo Zoo";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 22px;
  padding-top: 80px;
}

@media all and (max-width: 430px) {
  .parting-line {
    width: 100%;
  }
}
