.page-frame {
  &.xslego {
    .block-two {
      //   height: 60vh;
      background: linear-gradient(260.94deg, #95b7ff 4.68%, #254fa9 100.18%);
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 55%;
      }
    }
  }
}
@media all and (max-width: 1500px) {
  .page-frame {
    &.xslego {
      .block-two {
        img {
          width: 60%;
        }
      }
    }
  }
}

@media all and (max-width: 1200px) {
  .page-frame {
    &.xslego {
      .block-two {
        img {
          width: 70%;
        }
      }
    }
  }
}

@media all and (max-width: 1000px) {
  .page-frame {
    &.xslego {
      .block-two {
        img {
          width: 80%;
        }
      }
    }
  }
}
@media all and (max-width: 700px) {
  .page-frame {
    &.xslego {
      .block-two {
        img {
          width: 85%;
        }
      }
    }
  }
}
@media all and (max-width: 430px) {
  .page-frame {
    &.xslego {
      .block-two {
        img {
          width: 90%;
        }
      }
    }
  }
}
