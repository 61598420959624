.page-frame {
  &.ctbc {
    .block-two {
      background-color: #4aa5a7;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      .img-frame {
        width: 100%;
        // max-width: 1500px;
        height: 100%;
        position: relative;
        background-image: url(../../components/images/CTBC-UX-0-cover.png);
        background-size: 78%;
        background-repeat: no-repeat;
        background-position: 70% 42%;
      }
    }
  }
}

@media all and (min-width: 2047px) {
  .page-frame {
    &.ctbc {
      .block-two {
        .img-frame {
          background-size: 1500px;
          background-repeat: no-repeat;
          background-position: 55% 34%;
        }
      }
    }
  }
}

@media all and (max-width: 1200px) {
  .page-frame {
    &.ctbc {
      .block-two {
        .img-frame {
          background-size: 100%;
          background-position: 8vw center;
        }
      }
    }
  }
}

@media all and (max-width: 820px) {
  .page-frame {
    &.ctbc {
      .block-two {
        .img-frame {
          // background-size: 44vh, 74%;
          // background-repeat: no-repeat, no-repeat;
          // background-position: 96% 10vh, left -140px;
        }
      }
    }
  }
}

@media all and (max-width: 430px) {
  .page-frame {
    &.ctbc {
      .block-two {
        .img-frame {
          background-image: url(../../components/images/CTBC-UX-1-cover-right.png);
          background-size: 58%;
          background-repeat: no-repeat;
          background-position: 60% center;
        }
      }
    }
  }
}
