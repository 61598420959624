.project {
  width: 100%;
  //   height: calc(100vh - 30px);
  height: 90vh;
  .tech-tag {
    display: inline-block;
    width: auto;
    padding: 4px 8px;
    margin: 0 4px;
    border-radius: 17px;
    background-color: #3b424a91;
    color: #ffffff;
    &:first-child {
      margin-left: 8px;
    }
  }

  &.one {
    background-color: #138b81;
  }
  &.two {
    background-color: #2cb8bf;
  }
  &.three {
    background-color: #1b1c1c;
  }
  &.four {
    background-color: #95b7ff;
  }
  &.five {
    background-color: #62dfc6;
  }
  &.six {
    background-color: #1b1c1c;
    .tech-tag {
      // background-color: #2c4d83;
      // color: #cbdeff;
    }
  }
}
