.parting-line {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f8f8;
  font-family: $ZenTokyoZoo;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 42px;
  text-align: center;
  letter-spacing: 22px;
  padding-top: 80px;
}
@media all and (max-width: 430px) {
  .parting-line {
    width: 100%;
  }
}
