.page-frame {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  .block-two {
    width: 100%;
    height: 75vh;
  }
}
