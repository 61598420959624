* {
  p,
  h1,
  h2,
  h3,
  h4,
  h5 {
    padding: 0;
    margin: 0;
  }
}

body {
  margin: 0;
  padding: 0;
  //   font-family: "Roboto";
}

//scrollbar transparent
*::-webkit-scrollbar,
*::-webkit-scrollbar-thumb {
  width: 0px;
  border-radius: 0px;
  background-clip: padding-box;
  border: 0px solid transparent;
}
