.topBtn-frame {
  position: fixed;
  right: 5%;
  bottom: 13%;
  z-index: 10;
  cursor: pointer;
  .topBtn {
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 50%;
    border: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  svg {
    font-size: x-large;
    stroke-width: 1;
  }
  .topbtn-bg {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-image: url(../../components/images/btn-check-2x.png);
    background-size: contain;
    position: absolute;
    right: -7px;
    top: 4px;
    z-index: -2;
  }
}
