.page-frame {
  &.scsb {
    .block-two {
      //   height: 60vh;
      background-color: #f2f2f2;
      display: flex;
      align-items: center;
      justify-content: center;
      .img-frame {
        width: 100%;
        height: 100%;
        // max-width: 1500px;
        position: relative;
        background-image: url(../../components/images/shanghai-3-cover.png),
          url(../../components/images/shanghai-2-bk.png);
        background-size: 85%, cover;
        background-repeat: no-repeat, no-repeat;
        background-position: center 42%;
      }
    }
    .template {
      .gif-frame {
        display: flex;
        flex-direction: column;
        align-items: center;
        .phone-gif1 {
          width: 28%;
        }
        .phone-gif2 {
          width: 30%;
          margin-bottom: 80px;
        }
        .phone-gif3 {
          width: 60%;
          margin-bottom: 80px;
        }
      }
      &.one {
        .info-frame {
          img {
            &.design-img {
              width: 70%;
              align-self: center;
              margin-bottom: 80px;
            }
          }
        }
      }
    }
  }
}

@media all and (min-width: 2047px) {
  .page-frame {
    &.scsb {
      .block-two {
        .img-frame {
          background-image: url(../../components/images/shanghai-3-cover.png),
            url(../../components/images/shanghai-2-bk.png);
          background-size: 1500px, cover;
          background-repeat: no-repeat, no-repeat;
          background-position: center, center 48%;
        }
      }
    }
  }
}

@media all and (max-width: 1200px) {
  .page-frame {
    &.scsb {
      .block-two {
        .img-frame {
          background-size: 110%, cover;
        }
      }
    }
  }
}

@media (max-width: 820px) {
  .page-frame {
    &.scsb {
      .block-two {
        .img-frame {
          background-image: url(../../components/images/shanghai-3-cover.png),
            url(../../components/images/shanghai-2-bk.png);
          background-size: 100%, cover;
          background-repeat: no-repeat, no-repeat;
          background-position: 1vw, center;
        }
      }
      .template {
        .gif-frame {
          .phone-gif1 {
            width: 55%;
          }
          .phone-gif2 {
            width: 55%;
          }
          .phone-gif3 {
            width: 70%;
          }
        }
        &.one {
          .info-frame {
            img {
              &.design-img {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .page-frame {
    &.scsb {
      .block-two {
        .img-frame {
          background-image: url(../../components/images/shanghai-1-cover.png),
            url(../../components/images/shanghai-2-bk.png);
          background-size: 125%, cover;
          background-repeat: no-repeat, no-repeat;
          background-position: center, center 48%;
        }
      }
      .template {
        .gif-frame {
          .phone-gif1 {
            width: 65%;
          }
          .phone-gif2 {
            width: 65%;
          }
          .phone-gif3 {
            width: 100%;
          }
        }
      }
    }
  }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .page-frame {
    &.scsb {
      .block-two {
        .img-frame {
          background-image: url(../../components/images/shanghai-3-cover.png),
            url(../../components/images/shanghai-2-bk.png);
          background-size: 100%, cover;
          background-repeat: no-repeat, no-repeat;
          background-position: center, center 48%;
        }
      }
      .template {
        .gif-frame {
          .phone-gif1 {
            width: 55%;
          }
          .phone-gif2 {
            width: 55%;
          }
          .phone-gif3 {
            width: 70%;
          }
        }
      }
    }
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .page-frame {
    &.scsb {
      .block-two {
        .img-frame {
          background-image: url(../../components/images/shanghai-3-cover.png),
            url(../../components/images/shanghai-2-bk.png);
          background-size: 100%, cover;
          background-repeat: no-repeat, no-repeat;
          background-position: center, center 48%;
        }
      }
      .template {
        .gif-frame {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            // width: 100%;
          }
          .phone-gif1 {
            width: 55%;
          }
          .phone-gif2 {
            width: 55%;
            margin-bottom: 80px;
          }
          .phone-gif3 {
            width: 70%;
          }
        }
        &.one {
          .info-frame {
            img {
              &.design-img {
                width: 100%;
                align-self: center;
                margin-bottom: 80px;
              }
            }
          }
        }
      }
    }
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .page-frame {
    &.scsb {
      .block-two {
        .img-frame {
          background-image: url(../../components/images/shanghai-3-cover.png),
            url(../../components/images/shanghai-2-bk.png);
          background-size: 100%, cover;
          background-repeat: no-repeat, no-repeat;
          background-position: center, center 48%;
        }
      }
      .template {
        .gif-frame {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            // width: 100%;
          }
          .phone-gif1 {
            width: 55%;
          }
          .phone-gif2 {
            width: 55%;
            margin-bottom: 80px;
          }
          .phone-gif3 {
            width: 70%;
          }
        }
        &.one {
          .info-frame {
            img {
              &.design-img {
                width: 100%;
                align-self: center;
                margin-bottom: 80px;
              }
            }
          }
        }
      }
    }
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .page-frame {
    &.scsb {
      .block-two {
        .img-frame {
          background-image: url(../../components/images/shanghai-3-cover.png),
            url(../../components/images/shanghai-2-bk.png);
          background-size: 100%, cover;
          background-repeat: no-repeat, no-repeat;
          background-position: center, center 48%;
        }
      }
      .template {
        .gif-frame {
          display: flex;
          flex-direction: column;
          align-items: center;
          img {
            // width: 100%;
          }
          .phone-gif1 {
            width: 55%;
          }
          .phone-gif2 {
            width: 55%;
            margin-bottom: 80px;
          }
          .phone-gif3 {
            width: 70%;
          }
        }
        &.one {
          .info-frame {
            img {
              &.design-img {
                width: 100%;
                align-self: center;
                margin-bottom: 80px;
              }
            }
          }
        }
      }
    }
  }
}
