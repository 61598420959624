@import url('https://fonts.googleapis.com/css2?family=Urbanist&family=Cabin&family=Zen+Tokyo+Zoo&family=JetBrains+Mono');

$Cabin: 'Cabin';
$Urbanist: 'Urbanist';
$ZenTokyoZoo: 'Zen Tokyo Zoo';
$Roboto: 'Roboto';

body {
  margin: 0;
  padding: 0;
  font-family: 'Urbanist', 'Cabin', 'Zen Tokyo Zoo', 'JetBrains Mono', 'Roboto',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.wrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  border: 15px solid #000000;
  position: relative;
  .left {
    width: 100%;
    height: 100%;
    // border-right: 15px solid #000000;
    display: flex;
    .left-frame {
      width: 40%;
      position: sticky;
      left: 0;
      top: 0;
      border-right: 15px solid #000000;
    }
    // position: sticky;
    // left: 0;
    // top: 0;
    .right {
      width: 60%;
      height: 100%;
      // position: fixed;
      // top: 0;
      // right: 0;
      // border-left: 7.5px solid #000000;
      overflow-x: hidden;
      overflow-y: auto;
      position: relative;
      .about {
        width: 100%;
        height: 90vh;
        .bg-block {
          width: 90%;
          height: 95%;
          // background-color: #8effe4; //version1.0
          background-color: #a1dbda; //version-blue-green
          // background-color: #95b6f5;

          border-radius: 0px 0px 130px 0px;
          display: flex;
          align-items: center;
          justify-content: center;
          .about-info {
            width: 60%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            h4 {
              margin-bottom: 1rem;
              font-family: $Urbanist;
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 24px;
              text-transform: uppercase;
              margin-top: 1rem;
            }
            p {
              font-family: $Urbanist;
              font-style: normal;
              font-weight: 400;
              font-size: 18px;
              line-height: 30px;
              margin-bottom: 1rem;
            }
            .icon-frame {
              display: flex;
              align-items: center;
              img {
                width: 2.5rem;
                margin-right: 1rem;
              }
            }
          }
        }
      }
      .project {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 2rem 0;
        .info-frame {
          padding: 0 16px;
          //adjust to 100% because of the tech-tag
          max-width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .title {
            display: flex;
            align-items: center;
            h1 {
              font-family: $Urbanist;
              font-style: normal;
              font-weight: 700;
              font-size: 20px;
              line-height: 24px;
              text-transform: capitalize;
            }
            h3 {
              font-family: $Urbanist;
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 19px;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 12px;
            }
            h5 {
              font-family: $Urbanist;
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 17px;
            }
            &.flex-start {
              align-items: flex-start;
            }
          }
          &.white {
            color: white;
          }
          h4 {
            margin-top: 4px;
            margin-bottom: 8px;
            font-family: $Urbanist;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            /* identical to box height */

            text-transform: capitalize;
          }
          a {
            margin-top: 8px;
          }
        }
        &.one {
          background-color: #138b81;
          align-items: flex-start;
          .img-frame {
            width: 85%;
            height: 100%;
            align-self: flex-end;
            background-image: url(../../components/images/project_ctbc.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            // img {
            //   width: 100%;
            //   height: 100%;
            // }
          }
        }
        &.two {
          background-color: #2cb8bf;
          .img-frame {
            width: 100%;
            height: 100%;
            align-self: flex-end;
            background-image: url(../../components/images/shanghai-0-prototype.gif);
            background-size: 28%;
            background-repeat: no-repeat;
            background-position: center;
            display: flex;
            align-items: center;
            justify-content: center;
            // img {
            //   width: 40%;
            // }
          }
        }
        &.three {
          background-color: #1b1c1c;
          .img-frame {
            width: 100%;
            height: 100%;
            align-self: flex-end;
            background-image: url(../../components/images/project_frontlineAirsoft.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        &.four {
          background-color: #95b7ff;
          align-items: flex-start;
          .img-frame {
            width: 85%;
            height: 100%;
            align-self: flex-end;
            background-image: url(../../components/images/xslego-1-cover.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        &.five {
          background-color: #62dfc6;
          .img-frame {
            width: 100%;
            height: 100%;
            align-self: flex-end;
            background-image: url(../../components/images/project_tcbBank.png);
            background-size: 120%;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
        &.six {
          // background-color: #1b1c1c; // black
          // background-color: #ffb73d; // pair with block top
          // background-color: #cff068;
          background-color: #ffe978;
          background-color: #92b4ea;
          // background-color: #4398e2;

          .img-frame {
            width: 100%;
            height: 100%;
            align-self: center;
            background-image: url(../../components/images/project_alphritter-2.png);
            /* border-radius: 50px; */
            background-size: 90%;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }
    }
  }
}
.page {
  position: absolute;
  width: 100vw;
  height: 100vh;
  // right: -15px;
  right: 0;
  bottom: 0;
  opacity: 0;
  // border-radius: 50px 50px 0 0;
  // border-radius: 50%;
  // background-color: #62dfc6;
  background-color: white;
  // border: 15px solid #000000;
  &.open {
    // width: 100vw;
    // height: 100vh;
    // right: 0;
    // bottom: 0;
    transition: 0.8s;
    opacity: 1;
    z-index: 3;
  }
}

.left-layer {
  position: fixed;
  width: 200%;
  height: 200%;
  top: 0;
  // top: auto;
  right: -200%;
  // bottom: auto;
  // left: auto;
  background-color: #138b81;
  transition: all 0.7s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 5;
  &.active {
    right: 200%;
  }
  &.left-layer--2 {
    background-color: #fed002;
    transition-delay: 0.3s;
  }
  &.left-layer--3 {
    background-color: #51a2a5;
    transition-delay: 0.3s;
  }
  &.left-layer--4 {
    background-color: #9dd3d4;
    transition-delay: 0.3s;
  }
  // &.left-layer--5 {
  //   background-color: #fed002;
  //   transition-delay: 0.3s;
  // }
}

@media all and (max-width: 820px) {
  .wrapper {
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    .left {
      .left-frame {
        width: 100%;
        height: 100vh;
        position: relative;
        border-right: none;
        border-bottom: 15px solid #000000;
      }
      width: 100%;
      height: 100%;
      border-right: none;
      flex-direction: column;
      .right {
        width: 100%;
      }
      .project {
        &.two {
          .img-frame {
            background-size: 35%;
          }
        }
      }
    }
    .left-layer {
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      right: -100%;
      bottom: auto;
      left: auto;
      z-index: 5;
      &.active {
        right: 100%;
      }
    }
  }
}
@media all and (max-width: 430px) {
  .wrapper {
    .left {
      .right {
        .project {
          .info-frame {
            max-width: 100%;
          }
          &.one {
            .img-frame {
              background-size: 90vh;
              background-repeat: no-repeat;
              background-position: left center;
            }
          }
          &.two {
            .img-frame {
              background-size: 65%;
            }
          }
          .tech-tag {
            margin-bottom: 8px;
          }
        }
        .about {
          height: 100%;
          .bg-block {
            padding: 2rem 0;
            margin-bottom: 2rem;

            .about-info {
              width: 95%;
              .icon-frame {
                img {
                  width: 2rem;
                }
              }
              p {
                font-size: 16px;
                line-height: 28px;
              }
            }
          }
        }
      }
    }
  }
}

/* ----------- iPad 1, 2, Mini and Air ----------- */

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .wrapper {
    .left {
      .right {
        .project {
          &.one {
            .img-frame {
              background-size: 135%;
              background-position: left center;
            }
          }
          &.two {
            .img-frame {
              background-size: 35%;
            }
          }
        }
      }
    }
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Portrait */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .wrapper {
    .left {
      .right {
        .project {
          &.one {
            .img-frame {
              background-size: 135%;
              background-position: left center;
            }
          }
          &.two {
            .img-frame {
              background-size: 35%;
            }
          }
        }
      }
    }
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 834px) and (max-device-width: 834px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .wrapper {
    .left {
      .right {
        .project {
          &.one {
            .img-frame {
              background-size: 135%;
              background-position: left center;
            }
          }
          &.two {
            .img-frame {
              background-size: 50%;
            }
          }
        }
      }
    }
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen and (min-device-width: 1024px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .wrapper {
    .left {
      .right {
        .project {
          &.one {
            .img-frame {
              background-size: 135%;
              background-position: left center;
            }
          }
          &.two {
            .img-frame {
              background-size: 50%;
            }
          }
        }
      }
    }
  }
}
