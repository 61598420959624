.page-frame {
  &.fa {
    .block-two {
      background-color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 50%;
      }
    }
  }
}
@media all and (max-width: 1500px) {
  .page-frame {
    &.fa {
      .block-two {
        img {
          width: 60%;
        }
      }
    }
  }
}

@media all and (max-width: 1200px) {
  .page-frame {
    &.fa {
      .block-two {
        img {
          width: 70%;
        }
      }
    }
  }
}

@media all and (max-width: 1000px) {
  .page-frame {
    &.fa {
      .block-two {
        img {
          width: 80%;
        }
      }
    }
  }
}
@media all and (max-width: 700px) {
  .page-frame {
    &.fa {
      .block-two {
        img {
          width: 85%;
        }
      }
    }
  }
}
@media all and (max-width: 430px) {
  .page-frame {
    &.fa {
      .block-two {
        img {
          width: 90%;
        }
      }
    }
  }
}
