footer {
  &.home-footer {
    width: 100%;
    height: 15vh;
    background-color: black;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    .top {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      h3 {
        margin-right: 0.5rem;
      }
      a {
        color: white;
        &:link {
          text-decoration: none;
          color: white;
        }
        &:visited {
          text-decoration: none;
          color: white;
        }
        &:hover {
          text-decoration: none;
          cursor: pointer;
          color: #25bdbf;
        }
        &:active {
          text-decoration: none;
          color: white;
        }
      }
      .link-frame {
        display: flex;
      }
    }
  }
  &.project-footer {
    width: 100%;
    height: 15vh;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-around;
    color: white;
    a {
      list-style: none;
      color: white;
      font-family: "Urbanist";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      text-align: right;
      text-transform: uppercase;
      text-decoration: none;
      display: flex;
      &.prev {
        display: flex;
        .prev-detail {
          margin-left: 1rem;
        }
      }
      &.next {
        display: flex;
        .next-detail {
          margin-right: 1rem;
        }
      }
      &:hover {
        cursor: pointer;
        color: #25bdbf;
      }
    }
  }
}
@media all and (max-width: 430px) {
  footer {
    &.home-footer {
      .top {
        flex-direction: column;
      }
    }
    &.project-footer {
      a {
        flex-direction: column;
        &.prev {
          flex-direction: column;
          align-items: flex-start;
          .prev-detail {
            margin-left: 0rem;
          }
        }
        &.next {
          flex-direction: column-reverse;
          align-items: flex-end;
          .next-detail {
            margin-right: 0rem;
          }
        }
      }
    }
  }
}
