.project-info {
  width: 100vw;
  max-width: 1500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px 0;
    .inverted-1 {
      display: flex;
      align-items: center;
      background: linear-gradient(black 50%, #fff 0) 0 var(--_d, 0) / 100% 200%
          no-repeat,
        linear-gradient(#0000 50%, #000 0) 0 var(--_d, 0) / 100% 200% no-repeat;
      -webkit-background-clip: text, padding-box;
      background-clip: text, padding-box;
      transition: 0.8s;
      font-size: 16px;
      a {
        text-decoration: none;
        color: black;
        font-weight: bolder;
        color: #0000;
        font-weight: 700;
        display: inline-block;
        font-size: 20px;
        padding: 8px;
        font-family: $Urbanist;
        text-transform: uppercase;
        cursor: pointer;
      }
      span {
        font-family: system-ui;
        font-weight: bolder;
      }
    }
    .inverted-1:hover {
      --_d: 50%;
    }
    .backBtn {
      --s: 0.1em; /* the thickness of the line */
      --c: #000000; /* the color */

      color: #0000;
      padding-bottom: var(--s);
      background: linear-gradient(90deg, var(--c) 50%, #000 0)
          calc(100% - var(--_p, 0%)) / 200% 100%,
        linear-gradient(var(--c) 0 0) 0% 100% / var(--_p, 0%) var(--s) no-repeat;
      -webkit-background-clip: text, padding-box;
      background-clip: text, padding-box;
      transition: 0.5s;
      font-family: $Urbanist;
      a {
        text-decoration: none;
        font-weight: bolder;
        color: #000000;
        font-weight: 700;
        display: inline-block;
        font-size: 20px;
        padding: 8px;
        font-family: $Urbanist;
        text-transform: uppercase;
        cursor: pointer;
      }
      &:hover {
        --_p: 100%;
      }
    }
  }
  .main-block {
    width: 80%;
    // height: 80%;
    border: 15px solid black;
    background-color: #f8f8f8;
    border-bottom: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0;
    .main-frame {
      width: 80%;
      // height: 60%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .title {
        margin-bottom: 12px;
        font-family: "JetBrains Mono";
        font-size: 42px;
        line-height: 52px;
        display: block;
        font-weight: 400;
      }
      .type {
        font-family: $Urbanist;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 29px;
        margin-bottom: 16px;
      }
      p {
        font-family: $Urbanist;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 1.7em;
        margin-bottom: 10px;
      }
      .detail {
        font-weight: 400;
        font-size: 20px;
        line-height: 1.7em;
        margin-bottom: 0;
      }
      .line {
        border: 1px solid black;
        margin: 20px 0;
      }
      .bottom {
        width: 100%;
        display: flex;
        justify-content: space-between;
        z-index: 2;
      }
      .left {
        width: 50%;
        display: flex;
        justify-content: space-between;
      }
      .role,
      .respon {
        display: flex;
        flex-direction: column;
        h3 {
          margin-bottom: 1rem;
        }
        p {
          width: 100%;
          // margin-bottom: 1rem;
        }
      }
    }
  }
}

@media all and (max-width: 1000px) {
  .project-info {
    .main-block {
      .main-frame {
        .left {
          width: 65%;
        }
      }
    }
  }
}

@media all and (max-width: 840px) {
  .project-info {
    .main-block {
      .main-frame {
        .bottom {
          flex-direction: column;
          .left {
            width: 100%;
          }
        }
      }
    }
  }
}

@media all and (max-width: 430px) {
  .project-info {
    .header {
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 26px 0;
      img {
        width: 60px;
        height: 60px;
      }
    }

    .main-block {
      width: 100%;
      box-sizing: border-box;
      padding: 36px 0;
      .main-frame {
        width: 90%;
        p {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
        }
        .detail {
          font-weight: 400;
          font-size: 18px;
          line-height: 28px;
        }
        .bottom {
          flex-direction: column;
          .left {
            flex-direction: column;
            width: 100%;
            .role {
              margin-bottom: 0.5rem;
            }
          }
          .btn-frame {
            position: relative;
            margin-bottom: 8px;
            margin-top: 24px;
          }
        }
      }
    }
  }
}
